import { useQuery } from "@tanstack/react-query";

import { OpsClass } from "@models/ops-class";
import * as OpsService from "@services/ops.service";
import { OpsClassWithModifiers } from "@models/ops-class-with-modifiers";


const useGetOpsClass = (version: string, id: string | null): { data?: OpsClass | OpsClassWithModifiers | null, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["OpsClass", id],
    queryFn: () => id ? OpsService.getOpsClass(version, id) : null,
  });

  return {
    data: data,
    isPending,
  };
};

export default useGetOpsClass;
