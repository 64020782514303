import { cn } from "@lib/utils";
import { Button } from "@ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";
import { Info } from "lucide-react";
import * as React from "react";


export function ReasonPopover({
  className,
  reason,
}: React.HTMLAttributes<HTMLDivElement> & {
  reason: string[];
}) {
  return (
    <span className={cn("inline-flex items-center justify-center ml-1", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="h-auto w-auto p-0"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Info className="text-black/50 mr-0 h-5 w-5" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-60 bg-gray-100" align="center">
          <div className="text-black/70 text-xs md:text-light">{reason}</div>
        </PopoverContent>
      </Popover>
    </span>
  );
}
