import { useQuery } from "@tanstack/react-query";

import * as OpsService from "@services/ops.service";
import { OpsModifier } from "@models/ops-modifier";


const useGetOpsModifier = (version: string, id: string | null): { data: OpsModifier | null, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["OpsModifiers", id],
    queryFn: () => id ? OpsService.getOpsModifier(version, id) : null,
  });

  return {
    data: data || null,
    isPending,
  };
};

export default useGetOpsModifier;
