import { OpsClass } from "@models/ops-class";
import { QueryListOptions } from "@services/base-query.service";
import { IcdClass } from "@models/icd-class";
import { Tab } from "@pages/case-page-details";
import useGetOpsSubClasses from "./useGetOpsSubClasses";
import useGetIcdSubClasses from "./useGetIcdSubClasses";


const useGetOPSorICDSubClasses = (version: string, id: string | null, activeTab: Tab, options?: QueryListOptions): { data: OpsClass[] | IcdClass[], isPending: boolean } => {
    if (activeTab === "icdCodes") {
        return useGetIcdSubClasses(version, id, options);
    } else  {
        return useGetOpsSubClasses(version, id, options);
    }
};

export default useGetOPSorICDSubClasses;
