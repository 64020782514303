import { fetcherWithToken, postWithToken, removeWithToken } from "@lib/request";

import { MedicalReport } from "@models/medical-report";
import { MedicalReportCode, MedicalReportProcess, MedicalReportProcessState } from "@models/medical-report-process";
import { QueryListResponse } from "@services/base-query.service";
import { ProcessQueryListOptions } from "@services/medical-report.service";
import { readDocxDocument } from "@utils/docx-file.utils";
import { readPdfDocument } from "@utils/pdf-file.utils";
import { toQueryStrings } from "@utils/query-options.utils";


export const parseMedicalReport = async (filename: string, document: ArrayBuffer): Promise<string> => {
  if (filename.toLowerCase().endsWith('docx')) {
    return readDocxDocument(document);
  } else {
    return readPdfDocument(document);
  }
};

export const anonymizeMedicalReport = async (document: string): Promise<string> => {
  // TODO: instead of removing find a way to just mark the text so the editor won't break the style
  // const removedPatient = removeStringsAfterTheWord(text, "Herr");
  // const removedDoctor = findTextAfterTheFollowingAndRemove(removedPatient, "Arzt");
  // const removedDr = findTextAfterTheFollowingAndRemove(removedDoctor, "Dr.");
  // const removedMed = findTextAfterTheFollowingAndRemove(removedDr, "med.");

  return document;
};

export const createMedicalReport = async (accountId: string, filename: string | undefined, document: string, customKey: string, procedureYear: string): Promise<MedicalReport> => {
  const response = await postWithToken<MedicalReport>(
    `${import.meta.env.VITE_API_BASE}/accounts/${accountId}/medical-reports`,
    { filename, document, customKey, procedureYear }
  );

  return response;
};

export const getMedicalReport = async (accountId: string, id: string): Promise<MedicalReport> => {
  return await fetcherWithToken<MedicalReport>(`${import.meta.env.VITE_API_BASE}/accounts/${accountId}/medical-reports/${id}`);
};

export const updateMedicalReportProcessCodes = async (accountId: string, id: string, properties: { state?: MedicalReportProcessState, opsCodes?: MedicalReportCode[], icdCodes?: MedicalReportCode[]  }): Promise<MedicalReportProcess> => {
  const response = await postWithToken<MedicalReportProcess>(
    `${import.meta.env.VITE_API_BASE}/accounts/${accountId}/medical-report-processes/${id}/codes`,
    properties
  );

  return response;
};

export const getMedicalReportProcess = async (accountId: string, id: string): Promise<MedicalReportProcess> => {
  return await fetcherWithToken<MedicalReportProcess>(`${import.meta.env.VITE_API_BASE}/accounts/${accountId}/medical-report-processes/${id}`);
};

export const getMedicalReportProcesses = async (accountId: string, options?: ProcessQueryListOptions): Promise<QueryListResponse<MedicalReportProcess>> => {
  const queryOptions = toQueryStrings(options);
  return await fetcherWithToken<QueryListResponse<MedicalReportProcess>>(
    `${import.meta.env.VITE_API_BASE}/accounts/${accountId}/medical-report-processes/${queryOptions ? "?" + queryOptions : ""}`
  );
};

export const deleteMedicalReportProcess = async (accountId: string, id: string): Promise<MedicalReportProcess> => {
  return await removeWithToken<MedicalReportProcess>(`${import.meta.env.VITE_API_BASE}/accounts/${accountId}/medical-report-processes/${id}`);
};
