import { cn } from "@lib/utils";
import * as React from "react";
import debounce from "lodash.debounce";

import { Badge } from "@ui/badge";
import { Icons } from "@components/icons";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  onDebouncedInputChange?: (inputValue: string) => void;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, value: initialValue = "", onDebouncedInputChange, ...props }, ref) => {
    const [inputValue, setInputValue] = React.useState(initialValue);

    // Memoize the debounced function to prevent it from being recreated with each rendition
    const debouncedChangeHandler = React.useMemo(
      () =>
        debounce((value: string) => {
          if (onDebouncedInputChange) {
            onDebouncedInputChange(value);
          }
        }, 800), // Delay in milliseconds
      [onDebouncedInputChange]
    );

    React.useEffect(() => {
      // Clean up the debounced function when unmounting the component
      return () => {
        debouncedChangeHandler.cancel();
      };
    }, [debouncedChangeHandler]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
      if (props.onChange) props.onChange(event);
      debouncedChangeHandler(value);
    };

    const handleClear = () => {
      setInputValue("");
      if (props.onChange) {
        props.onChange({ ...props, target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    return (
      <div 
        className={cn(
          "w-full relative",
        className,
        )}>
        <input
          type={type}
          className="flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 pr-12 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          ref={ref}
          value={inputValue}
          {...props}
          onChange={handleInputChange}
        />
        {inputValue && (
          <Badge variant="default" className="ml-2 absolute right-3 top-1/2 transform -translate-y-1/2" onClick={(e) => {
            handleClear();
            e.preventDefault();
            e.stopPropagation();
          }}>
            <Icons.close className="h-3 w-3" />
          </Badge>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
