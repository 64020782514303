import { fetcherWithToken } from "@lib/request";

import { IcdClass } from "@models/icd-class";
import { IcdModifier } from "@models/icd-modifier";
import { IcdClassWithModifiers } from "@models/icd-class-with-modifiers";
import { CodeSearchResult } from "@models/code-search-result";
import { QueryListOptions, QueryListResponse } from "@services/base-query.service";
import { toQueryStrings } from "@utils/query-options.utils";

export const getIcdClass = async (version: string, id: string): Promise<IcdClass | IcdClassWithModifiers> => {
  const { class: codeClass } = await fetcherWithToken<{ class: IcdClass | IcdClassWithModifiers }>(`${import.meta.env.VITE_API_BASE}/icd-classes/${version}/${id}`);
  return codeClass;
};

export const getIcdSubClasses = async (version: string, superclassId: string | null, options?: QueryListOptions): Promise<QueryListResponse<IcdClass>> => {
  const queryOptions = toQueryStrings(options);
  return await fetcherWithToken<QueryListResponse<IcdClass>>(
    `${import.meta.env.VITE_API_BASE}/icd-classes/${version}?superclass=${superclassId || ''}${queryOptions ? "&" + queryOptions : ""}`
  );
};

export const getIcdModifier = async (version: string, id: string): Promise<IcdModifier> => {
  return await fetcherWithToken<IcdModifier>(`${import.meta.env.VITE_API_BASE}/icd-modifiers/${version}/${id}`);
};

export const searchIcd = async (version: string, searchTerm: string, options: Pick<QueryListOptions, 'limit'>): Promise<CodeSearchResult[]> => {
  const { data } = await fetcherWithToken<QueryListResponse<CodeSearchResult>>(`${import.meta.env.VITE_API_BASE}/icd/${version}/?search=${searchTerm}&limit=${options.limit || 10}`);
  return data;
};
