import { MedicalReport } from "@models/medical-report";
import { MedicalReportCode, MedicalReportProcess, MedicalReportProcessState } from "@models/medical-report-process";
import * as MedicalReportAccess from "@access/medical-report.access";
import { QueryListOptions, QueryListResponse } from "./base-query.service";


export interface ProcessQueryListOptions extends QueryListOptions {
  searchString?: string;
  states?: MedicalReportProcessState[];
  createdByIds?: string[];
  updatedByIds?: string[];
  /** For example: "2024-05-22" */
  createdAtDate?: string;
  /** For example: "2024-05-22" */
  updatedAtDate?: string;
}

export async function parseMedicalReport(filename: string, document: ArrayBuffer): Promise<string> {
  const report = await MedicalReportAccess.parseMedicalReport(filename, document);
  return anonymizeMedicalReport(report);
}

export async function anonymizeMedicalReport(document: string): Promise<string> {
  return await MedicalReportAccess.anonymizeMedicalReport(document);
}

export async function createMedicalReport(accountId: string, filename: string | undefined, document: string, customKey: string, procedureYear: string): Promise<MedicalReport> {
  // removed marked words before uploading
  const anonymizedDocument = document.replace(/<mark>.*?<\/mark>/gs, 'XXXX');
  return await MedicalReportAccess.createMedicalReport(accountId, filename, anonymizedDocument, customKey, procedureYear);
}

export async function getMedicalReport(accountId: string, id: string): Promise<MedicalReport> {
  return await MedicalReportAccess.getMedicalReport(accountId, id);
}

export async function updateMedicalReportProcessCodes(accountId: string, id: string, properties: { state?: MedicalReportProcessState; opsCodes?: MedicalReportCode[]; icdCodes?: MedicalReportCode[] }): Promise<MedicalReportProcess> {
  return await MedicalReportAccess.updateMedicalReportProcessCodes(accountId, id, properties);
}

export async function getMedicalReportProcess(accountId: string, id: string): Promise<MedicalReportProcess> {
  return await MedicalReportAccess.getMedicalReportProcess(accountId, id);
}

export async function getMedicalReportProcesses(accountId: string, options?: ProcessQueryListOptions): Promise<QueryListResponse<MedicalReportProcess>> {
  return await MedicalReportAccess.getMedicalReportProcesses(accountId, options);
}

export async function deleteMedicalReportProcess(accountId: string, id: string): Promise<MedicalReportProcess> {
  return await MedicalReportAccess.deleteMedicalReportProcess(accountId, id);
}
