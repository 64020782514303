import { useQuery } from "@tanstack/react-query";

import * as IcdService from "@services/icd.service";
import { IcdClass } from "@models/icd-class";
import { IcdClassWithModifiers } from "@models/icd-class-with-modifiers";


const useGetIcdClass = (version: string, id: string | null): { data?: IcdClass | IcdClassWithModifiers | null, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["IcdClass", id],
    queryFn: () => id ? IcdService.getIcdClass(version, id) : null,
  });

  return {
    data: data,
    isPending,
  };
};

export default useGetIcdClass;
