import { useEffect, useMemo, useState } from "react";
import type {
  ComboBoxProps,
  ListBoxItemProps,
  ValidationResult,
} from "react-aria-components";
import {
  FieldError,
  ListBoxItem,
  Text,
  Button,
  ComboBox,
  Input,
  ListBox,
  Popover,
  Label,
} from "react-aria-components";
import debounce from "lodash.debounce";

import {Icons} from "@components/icons";

interface MyComboBoxProps<T extends object>
  extends Omit<ComboBoxProps<T>, "children"> {
  label?: string;
  placeholder?: string;
  description?: string | null;
  selectionBehavior?: "replace" | "toggle";
  errorMessage?: string | ((validation: ValidationResult) => string);
  children: React.ReactNode | ((item: T) => React.ReactNode);
  isLoading?: boolean;
  onDebouncedInputChange?: (inputValue: string) => void;
}

export function MyComboBox<T extends object>({
  label,
  description,
  errorMessage,
  children,
  placeholder,
  selectionBehavior,
  isLoading = false,
  onDebouncedInputChange,
  ...props
}: MyComboBoxProps<T>) {
  const [inputValue, setInputValue] = useState('');

  // Memoize the debounced function to prevent it from being recreated with each rendition
  const debouncedChangeHandler = useMemo(
    () =>
      debounce((value: string) => {
        if (onDebouncedInputChange) {
          onDebouncedInputChange(value);
        }
      }, 800), // Delay in milliseconds
    [onDebouncedInputChange]
  );

  useEffect(() => {
    // Clean up the debounced function when unmounting the component
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedChangeHandler(value);
  };

  return (
    <ComboBox {...props}>
      <Label>{label}</Label>
      <div className="border border-gray-300 rounded-md p-2 flex items-center">
        <Button className="mr-2">
          <Icons.search />
        </Button>
        <Input
          placeholder={placeholder ? placeholder : undefined}
          className={"border-0 focus:outline-none focus:ring-0"}
          value={inputValue}
          onChange={handleInputChange}
        />
        {isLoading && <Icons.spinner className="animate-spin text-muted-foreground flex self-end ml-auto" />}
      </div>
      {description && <Text slot="description">{description}</Text>}
      <FieldError>{errorMessage}</FieldError>
      <Popover className="bg-background p-4 shadow-2xl border rounded-md">
        <ListBox selectionBehavior={selectionBehavior}>{children}</ListBox>
      </Popover>
    </ComboBox>
  );
}

export function MyItem(props: ListBoxItemProps) {
  return (
    <ListBoxItem
      {...props}
      className={({isFocused, isSelected}) =>
        `my-item ${isFocused ? "focused" : ""} ${isSelected ? "selected" : ""}`
      }
    />
  );
}
