import { useQuery } from "@tanstack/react-query";

import * as IcdService from "@services/icd.service";
import { IcdModifier } from "@models/icd-modifier";


const useGetIcdModifier = (version: string, id: string | null): { data: IcdModifier | null, isPending: boolean } => {
  const { data, isPending } = useQuery({
    queryKey: ["IcdModifiers", id],
    queryFn: () => id ? IcdService.getIcdModifier(version, id) : null,
  });

  return {
    data: data || null,
    isPending,
  };
};

export default useGetIcdModifier;
