import { useQuery } from "@tanstack/react-query";

import { IcdClass } from "@models/icd-class";
import * as IcdService from "@services/icd.service";
import { QueryListOptions } from "@services/base-query.service";


const useGetIcdSubClasses = (version: string, id: string | null, options?: QueryListOptions): { data: IcdClass[], isPending: boolean } => {
  const { data: response, isPending } = useQuery({
    queryKey: ["IcdSubClasses", id, options],
    queryFn: () => IcdService.getIcdSubClasses(version, id, options),
  });

  return {
    data: response?.data || [],
    isPending,
  };
};

export default useGetIcdSubClasses;
