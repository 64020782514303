/* eslint-disable react-hooks/rules-of-hooks */
import { Icons } from "@components/icons";
import {DataTableColumnHeader} from "@components/table/data-table-column-header";
import {MedicalReportProcess, MedicalReportProcessState} from "@models/medical-report-process";
import {ColumnDef} from "@tanstack/react-table";
import {Badge} from "@ui/badge";
import {format, parseISO} from "date-fns";
import {t} from "i18next";
import {Link} from "react-router-dom";
import { getStateVariant } from "./components/CaseItem";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@ui/dropdown-menu";
import { Button } from "@ui/button";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";

import { User } from "@models/user";


export const getColumnCases = (
  user: User | null | undefined,
  handleAction: (type: 'reset' | 'delete', process: MedicalReportProcess) => Promise<void>,
): ColumnDef<MedicalReportProcess>[] => {
  const columnCases: ColumnDef<MedicalReportProcess>[] = [{
    accessorKey: "orderNr",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        className="w-[160px]"
        title={t("title")}
      />
    ),
    cell: ({row}) => {
      return (
        <Link to={`/cases/${row.original.id}/details`} className="w-full">
          <div className="w-full">
            <div className="p-0 h-auto flex flex-col text-left justify-start items-start hover:bg-transparent ">
              <span className="truncate w-[390px] font-display">
                {row.original.name}
              </span>
              {row.original.tags?.length ? (
                <div className="flex flex-row flex-wrap">
                  {row.original.tags.map((tag) => (
                    <Badge
                      key={tag}
                      variant="gray"
                      className="text-xs text-center mr-1 mt-1"
                    >
                      { tag }
                    </Badge>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </Link>
      );
    },
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "link",
    header: () => null,
    cell: ({row}) => (
      <Link
        to={`/cases/${row.original.id}/details`}
        className="w-full text-white absolute top-0 right-0 p-2 h-full"
      ></Link>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "createdAt",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        className="w-[140px]"
        title={t("created_header")}
      />
    ),
    cell: ({row}) => (
      <Link to={`/cases/${row.original.id}/details`} className="w-full">
        <div className=" text-left w-full">
          {row.getValue("createdAt")
            ? format(parseISO(row.getValue("createdAt")), "dd.MM.y, HH:mm")
            : "-"}
        </div>
      </Link>
    ),
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "createdBy",
    header: (ctx) => {
        return(
      <DataTableColumnHeader
      column={ctx.column}
      title={t("created_by")}
    />
    )},
    cell: ({row}) => {
      return (
        <Link to={`/cases/${row.original.id}/details`} className="w-full">
          <div className="w-full">{`${row.original.createdByName || "-"}`}</div>
        </Link>
      );
    },
    meta: {
        filterVariant: 'select',
    },
    enableColumnFilter: true,
    enableSorting: true,
    enableHiding: false,
  },
  {
    accessorKey: "status",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title={t("status")}
        className="text-right px-2.5"
      />
    ),
    cell: ({row}) => {
      return (
        <Link to={`/cases/${row.original.id}/details`} className="w-full">
          <div className="flex space-x-2 w-full justify-end">
            <Badge
            variant={getStateVariant(row.original.state)}
            className="text-xs text-center"
          >
            {JSON.parse(t("statuses"))[row.original.state]}
            {row.original.groupingResult && (row.original.state === MedicalReportProcessState.COMPLETED || row.original.state === MedicalReportProcessState.VERIFIED) ? (
              ` (${row.original.groupingResult.drg.code})`
            ) : row.original.drg && (row.original.state === MedicalReportProcessState.COMPLETED || row.original.state === MedicalReportProcessState.VERIFIED) ? (
              ` (${row.original.drg.code})`
            ) : null}
            {" "}
            {row.original.state === MedicalReportProcessState.DRAFT ||
            row.original.state === MedicalReportProcessState.PREPROCESSING ? (
              <Icons.spinner className="animate-spin mx-1 h-4 w-4" />
            ) : null}
          </Badge>
          </div>
        </Link>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "actions",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title={""}
        className="text-right"
      />
    ),
    cell: ({row}) => {
      return (
        <div className="flex w-full justify-end">
          {(user?.role === "Clinical Manager" || user?.role === "Medical Controller") && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                  <DotsHorizontalIcon className="h-4 w-4 rotate-90" />
                  <span className="sr-only">
                    Open menu
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                className="min-w-fit"
              >
                {user?.portalRole === "Admin" && [MedicalReportProcessState.ERROR, MedicalReportProcessState.PREPROCESSING].includes(row.original.state) ? (
                  <DropdownMenuItem>
                    <Button
                      variant="outline"
                      onClick={ () => handleAction('reset', row.original) }
                    >
                      <Icons.refresh />
                    </Button>
                  </DropdownMenuItem>
                ) : null}
                <DropdownMenuItem>
                  <Button
                    variant="outline"
                    onClick={ () => handleAction('delete', row.original) }
                  >
                    <Icons.deleteRing />
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  }];

  return columnCases;
};
