import { IcdClass } from "@models/icd-class";
import { IcdModifier } from "@models/icd-modifier";
import { IcdClassWithModifiers } from "@models/icd-class-with-modifiers";
import { CodeSearchResult } from "@models/code-search-result";
import * as IcdAccess from "@access/icd.access";
import { QueryListOptions, QueryListResponse } from "./base-query.service";

export const getIcdClass = async (version: string, id: string): Promise<IcdClass | IcdClassWithModifiers> => {
  return await IcdAccess.getIcdClass(version, id);
};

export const getIcdSubClasses = async (version: string, superclassId: string | null, options?: QueryListOptions): Promise<QueryListResponse<IcdClass>> => {
  return await IcdAccess.getIcdSubClasses(version, superclassId, options);
};

export const getIcdModifier = async (version: string, id: string): Promise<IcdModifier> => {
  return await IcdAccess.getIcdModifier(version, id);
};

export const searchIcd = async (version: string, searchTerm: string, options: Pick<QueryListOptions, 'limit'>): Promise<CodeSearchResult[]> => {
  return await IcdAccess.searchIcd(version, searchTerm, options);
};
