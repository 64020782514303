import { t } from "i18next";
import { useLayoutEffect } from "react";

import { Icons } from "@components/icons";
import useGetOPSorICDModifier from "@hooks/useGetOPSorICDModifier";
import { useDialogStore } from "@store/dialog.store";
import InnerModelDialog from "./inner-dialog";
import { Button } from "@ui/button";
import { Table, TableBody, TableCell, TableRow } from "@ui/table";
import { Tab } from "@pages/case-page-details";
import { IcdClass } from "@models/icd-class";
import { IcdClassWithModifiers } from "@models/icd-class-with-modifiers";
import { OpsClass } from "@models/ops-class";
import { OpsClassWithModifiers } from "@models/ops-class-with-modifiers";
import { OpsRubricKind } from "@models/ops-rubric";
import { composeCodeClass } from "@utils/code-class.utils";

const ModifierDialog = ({
  version,
  code,
  opsCode,
  handleProccessCase,
  activeTab
}: {
  version: string;
  code: string | null;
  opsCode: OpsClass | IcdClass;
  handleProccessCase: (newOpsCode: IcdClass | OpsClass | IcdClassWithModifiers | OpsClassWithModifiers) => void;
  activeTab: Tab;
}) => {
  const {show, setHeader} = useDialogStore((state) => state);
    const {data} = useGetOPSorICDModifier(version, code, activeTab);

    useLayoutEffect(()=>{
      setHeader((
        <div>
          {opsCode.code} {opsCode.name}
        </div>
      ));
    }, [opsCode])
  
    const listInclusion = data?.rubrics.filter(
      (rubric) => rubric.kind === OpsRubricKind.INCLUSION
    );
    const listExclusion = data?.rubrics.filter(
      (rubric) => rubric.kind === OpsRubricKind.EXCLUSION
    );
    const listNote = data?.rubrics.filter(
      (rubric) => rubric.kind === OpsRubricKind.NOTE
    );

    return ( 
      <div className="h-[80vh] overflow-y-scroll px-6">
        <div
          className="absolute top-6 text-sm left-4 cursor-pointer"
          onClick={() => {
            show({
              content: (
                <InnerModelDialog
                  activeTab={activeTab}
                  handleProccessCase={handleProccessCase}
                  version={version}
                  code={opsCode.superClass || null}
                />
              ),
              header: null,
            })
          }}
        >
          <Icons.chevronLeft />
        </div>

        {listInclusion && listInclusion.length > 0 ? (
          <div>
            <div className="text-sm font-bold">
              {t("inclusive")}
            </div>
            <ul className="list-disc list-inside">
              {listInclusion?.map((rubric, i) => {
                return (
                  <li className="text-sm opacity-75" key={i}>
                    {rubric.text}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        {listExclusion && listExclusion.length > 0 ? (
          <div>
            <div className="text-sm font-bold">
              {t("exclusive")}
            </div>
            <ul className="list-disc list-inside">
              {listExclusion?.map((rubric, i) => {
                return (
                  <li className="text-sm opacity-75" key={i}>
                    {rubric.text}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        {listNote && listNote.length > 0 ? (
          <div>
            <div className="text-sm font-bold">
              {t("note")}
            </div>
            <ul className="list-disc list-inside">
              {listNote?.map((rubric, i) => {
                return (
                  <li className="text-sm opacity-75" key={i}>
                    {rubric.text}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}

        <Table className="text-[12px]">
            <TableBody>
            {data?.subClasses?.map((modifier, idx)=>{
                return <TableRow key={idx} >
                    <TableCell>{modifier.code}</TableCell>
                    <TableCell>{modifier.name}</TableCell>
                    <TableCell className="flex justify-end ml-auto"><Button   variant="outline"
                          className="text-green-500" onClick={()=> handleProccessCase(activeTab === "icdCodes" ? {
                            ...opsCode,
                            id: composeCodeClass({ ...opsCode, modifierClasses: [modifier] } as IcdClassWithModifiers).code,
                            composedCode: composeCodeClass({ ...opsCode, modifierClasses: [modifier] } as IcdClassWithModifiers).code,
                            type: "icd_class_with_modifiers",
                            modifierClasses: [modifier],
                          } as IcdClassWithModifiers : {
                            ...opsCode,
                            id: composeCodeClass({ ...opsCode, modifierClasses: [modifier] } as OpsClassWithModifiers).code,
                            composedCode: composeCodeClass({ ...opsCode, modifierClasses: [modifier] } as OpsClassWithModifiers).code,
                            type: "ops_class_with_modifiers",
                            modifierClasses: [modifier],
                          } as OpsClassWithModifiers)}>  <Icons.check /></Button></TableCell>
                </TableRow>
            })}
                <TableRow>
                </TableRow>
            </TableBody>
        </Table>
      </div>
    );
}
 
export default ModifierDialog;