import { IcdClass } from "@models/icd-class";
import { IcdClassWithModifiers } from "@models/icd-class-with-modifiers";
import { OpsClass } from "@models/ops-class";
import { OpsClassWithModifiers } from "@models/ops-class-with-modifiers";
import { Tab } from "@pages/case-page-details";
import useGetIcdClass from "./useGetIcdClass";
import useGetOpsClass from "./useGetOpsClass";


const useGetOPSorICDClass = (version: string, id: string | null, activeTab: Tab): { data?: IcdClass | OpsClass | IcdClassWithModifiers | OpsClassWithModifiers | null, isPending: boolean } => {
    if (activeTab === "icdCodes") {
        return useGetIcdClass(version, id);
    } else  {
        return useGetOpsClass(version, id);
    }
 
};

export default useGetOPSorICDClass;
